import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide41/dataPageGuide41"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
      Cómo conectar con los Millennials con el autoservicio
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/autoservicio-millennials/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Los Millenials ocupan gran parte de la población económicamente activa, por eso es importante saber cómo las empresas pueden conectar con ellos. "
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Cómo conectar con los Millennials con el autoservicio"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/autoservicio-millennials/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Los Millenials ocupan gran parte de la población económicamente activa, por eso es importante saber cómo las empresas pueden conectar con ellos. "
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Atención al Cliente"
      location={location}
      typeGuide="guide41"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
